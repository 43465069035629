<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import avatar_icon from '@/assets/images/users/avatar-1.jpg'
import TabAgentInfo from './components/tab_agent_info.vue'

import {
  required,
} from "vuelidate/lib/validators";

import { getBrokerageApi } from "@/api/brokerage"
import {initAgentApi} from '@/api/agent'
import { financial } from '@/api/misc'

import {searchFee} from '@/api/misc'
import Choices from "choices.js";


export default {
  page: {
    title: "Agent",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props: {

    agent_id: {
      type: String
    }
  },

  data() {
    return {
      title: "Brokerage",
      items: [
        {
          text: "Agent",
          href: "/brokerage/agent",
        },
        {
          text: "View",
          active: true,
        },
      ],
      avatar: avatar_icon,
      edit: false,
      agent: {
        fees: {},
        fields: {
          tax_info : {},
        },
        bill: {items : []}
      },

      outstanding_bills : [],
      charged_items     : [],
      termination_date  : '',
      remarks           : '',

      fee_amount         : 0,
      fee_gst            : 0,

      charged_amount     : 0,
      charged_amount_gst : 0,
      charged_credit     : 0,
      current_fee_code   : '',
      fee_choice         : {},
      fee_list           : [],
    };
  },




  components: {
    Layout,
    PageHeader,
    TabAgentInfo,
    flatPickr

  },

  validations() {
   
      return {

        termination_date: { required },
      }
    
  },


  methods: {

    f(n, d = 2) {
      return financial(n, d)
    },

    onTabChange(old_tab, new_tab) {
      (old_tab)
      this.$refs.infoTabs.tabs[new_tab].data = this.agent
    },

    onSearchedFee(e) {
        searchFee({"search_str" : e.detail.value}).then((res) =>{
            this.fee_list = []
            res.data.map((f) =>{
                this.fee_list.push({
                    label : `${f.NAME} (${f.accounting_gl})`,
                    value : f.CODE,
                    fee : f
                })
            })
            this.fee_choice.clearChoices()
            this.fee_choice.setChoices(this.fee_list)
            this.fee_choice.showDropdown()
        })
        
    },

    onAddFee() {

      let fee = this.fee_list.find((f) => f.value == this.current_fee_code)

      this.charged_items.push({
        fee_code  : fee.fee.code,
        fee_name  : fee.fee.name,
        fee       : financial(Number(this.fee_amount), 2),
        fee_tax   : financial(Number(this.fee_gst), 2),
      })

      this.charged_amount     = this.charged_items.reduce((a, c) => a + Number(c.fee), 0)
      this.charged_amount_gst = this.charged_items.reduce((a, c) => a + Number(c.fee_tax), 0)
      
     
    },

    onRemoveFee(fee, idx) {
      this.charged_items.splice(idx, 1)
      this.charged_amount     = this.charged_items.reduce((a, c) => a + Number(c.fee), 0)
      this.charged_amount_gst = this.charged_items.reduce((a, c) => a + Number(c.fee_tax), 0)
    },

    onTerminate() {
      this.$alertify.confirmWithTitle(
          'Termination', 'Are you sure to Terminate',
          () => {
             this.doTermination()
          },
          () => {
          }
      );
    },


    doTermination() {
      this.$v.$touch()

      if (this.$v.$invalid == false) {
        let data = {
          agent_id          : this.$route.query.agent_id ,
          termination_date  : this.termination_date,
          remarks           : this.remarks,
          charge_fees       : this.charged_items,
        }
        initAgentApi().terminate_agent(data).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message(" Terminated ");
            this.$router.push({name : 'brokerage-agent'})
          }
        })
      }
    }


  },


  created() {
   

  },

  mounted() {

    getBrokerageApi().agentApi.agent_detail({ agent_id: this.$route.query.agent_id }).then((res) => {
      if (res.errCode == 0) {
        this.agent = Object.assign({}, res.data)
        if (!this.agent.fees) {
          this.agent.fees = {}
        }
        if (!this.agent.fields) {
          this.agent.fields = {}
        }

        if (!this.agent.fields.tax_info) {
          this.agent.fields.tax_info = {}
        }
      }
    })

    initAgentApi().query_all_unpaid_bills({agent_id : this.$route.query.agent_id}).then(res => {
      if (res.errCode == 0) {
        this.outstanding_bills = []
        this.outstanding_bills.push(...res.data)
      } 
    })

    this.fee_choice = new Choices('#choices-fee-input')

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal id="modal_charge_dialog" centered   title="Charge" size="lg"  :static="true" title-class="text-black font-18"  body-class="p-3"  hide-footer >
      <div class="row mb-3">
        <div class="col-md-4">
          <label class="form-label" >Charge Fee</label>
          <select class="form-control" v-model="current_fee_code" name="choices-fee-input"
            @search="onSearchedFee" id="choices-fee-input">
        </select>
        </div>

        <div class="col-md-2">
          <label class="form-label" >Amount </label>
          <input type="text" class="form-control"  v-model="fee_amount"/>
        </div>

        <div class="col-md-2">
          <label class="form-label" >Gst </label>
          <input type="text" class="form-control" v-model="fee_gst"/>
        </div>
        <div class="col-md-2 mt-4">
        </div>

        <div class="col-md-2 mt-4">
          <b-button variant="primary" @click="onAddFee">Add Fee</b-button>
        </div>

      </div>
    </b-modal>

    <div class="row"> 
        <TabAgentInfo :data="agent" ref="agentInfoRef" :show_term_btn="true" @terminate="onTerminate"/>
      <!-- end col -->
    </div>

    <div class="row pt-5" >
      <div class="col-xxl-12 col-lg-12">

        <div class="row" >
          <b-tabs class="mt-n5 pt-2" content-class="card card-body mt-0" nav-class="nav-tabs-custom"
            nav-wrapper-class="nav-tabs-custom" @activate-tab="onTabChange" ref="infoTabs" >

            <b-tab title="Charge" active class="card border shadow-none mb-5" style="min-height: 50vh;">
             
              <div class="card-header d-flex align-items-center col-md-12">
                 
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                      <h5 class="card-title">Termination Info</h5>
                  </div>

                  <div class="flex-grow-2">
                      <b-button variant="primary" v-b-modal.modal_charge_dialog>Charge</b-button>
                  </div>
              
                 
              </div>
                
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label class="form-label" >Termination Date</label>
                    <flat-pickr v-model="termination_date" placeholder="Select a date" class="form-control"
                                :class="{ 'is-invalid': $v.termination_date.$error }"></flat-pickr>
                    <div v-if="$v.termination_date.$error" class="invalid-feedback">
                      <span v-if="!$v.termination_date.required">This value is required.</span>
                    </div>
                          
                  </div>

                  <div class="col-md-9">
                    <label class="form-label" >Remarks </label>
                    <input type="text" class="form-control" v-model="remarks"/>
                  </div>

                </div>




              </div>


              <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                          <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                              02
                          </div>
                      </div>
                  </div>
                  <div class="flex-grow-1">
                      <h5 class="card-title">Charge</h5>
                  </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="table-responsive">
                    <table class="table align-middle table-nowrap table-centered mb-0">
                      <thead>
                        <tr>
                          <th style="width: 100px;">No.</th>
                          <th>Fee Code</th>
                          <th>Fee Name</th>
                          <th>Amount</th>
                          <th>GST</th>
                          <th class="text-end" style="width: 120px;">Total</th>
                          <th class="text-end" style="width: 40px;"></th>
                        </tr>
                      </thead>
                      <!-- end thead -->
                      <tbody>
                        <tr v-for="(item, idx) in charged_items" :key="'charge_items_'+idx">
                          <th scope="row">{{ idx + 1 }}</th>
                          <td>
                            <div>
                              <h5 class="text-truncate font-size-14 mb-1">{{ item.fee_code }} </h5>
                              <p class="text-muted mb-0"></p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <h5 class="text-truncate font-size-14 mb-1">{{ item.fee_name }} </h5>
                              <p class="text-muted mb-0"></p>
                            </div>
                          </td>
                          <td>${{ f(item.fee).toLocaleString() }}</td>
                          <td>${{ f(item.fee_tax).toLocaleString() }}</td>
                          <td class="text-end">${{ f(item.fee + item.fee_tax).toLocaleString() }}</td>

                          <td class="text-end">
                            <div class="row icon-demo-content-10 align-right">
                              <div class="col-xl-3 col-lg-4 col-sm-6"  @click="onRemoveFee(item, idx)">
                                <i class="uil-times font-size-4"></i></div>
                            </div>
                          </td>
                        </tr>
                        <!-- end tr -->

                        <tr>
                          <th scope="row" colspan="5" class="text-end">
                            Sub Total
                          </th>
                          <td class="text-end">${{ f(charged_amount +charged_amount_gst ).toLocaleString() }}</td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <th scope="row" colspan="5" class="border-0 text-end">
                            Credit :
                          </th>
                          <td class="border-0 text-end">- ${{ f(charged_credit).toLocaleString() }}</td>
                        </tr>
                        <!-- end tr -->
                        <tr>
                          <th scope="row" colspan="5" class="border-0 text-end">
                            Total
                          </th>
                          <td class="border-0 text-end">
                            <h4 class="m-0 fw-semibold">${{  f(charged_amount +charged_amount_gst + charged_credit).toLocaleString() }}</h4>
                          </td>
                        </tr>
                        <!-- end tr -->
                      </tbody>
                      <!-- end tbody -->
                    </table>
                    <!-- end table -->
                  </div>
                  <!-- end table responsive -->
                </div>
              </div>

            </b-tab>
            <!-- end Tab Charge-->

            <b-tab title="Outstanding Bills"  class="card" style="min-height: 50vh;">
              <div class="row" v-if="outstanding_bills.length <= 0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4">
                      <label>No Outstanding Bills</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" v-for="bill, idx in outstanding_bills" :key="'agent_bill_'+idx+'_'+bill.id">
                <div class="card-body">

                  <div class="invoice-title">
                    <div class="text-muted">
                      <h4 class="float-end font-size-15">
                        <span class="badge bg-success font-size-12 ms-2" > Outstanding</span>
                      </h4>
                      <p class="mb-1">Bill Statement Month:{{ bill.bill_month }}</p>
                      <p class="mb-1">Invoice #:{{ bill.invoice_number }}</p>
                    
                    </div>
                  </div>

                  <hr class="my-4" />
                  <div class="py-2">
                    <h5 class="font-size-15">Bill Summary</h5>

                    <div class="table-responsive">
                      <table class="table align-middle table-nowrap table-centered mb-0">
                        <thead>
                          <tr>
                            <th style="width: 100px;">No.</th>
                            <th>Fee Code</th>
                            <th>Fee Name</th>
                            <th>Amount</th>
                            <th>GST</th>
                            <th class="text-end" style="width: 120px;">Total</th>
                          </tr>
                        </thead>
                        <!-- end thead -->
                        <tbody>
                          <tr v-for="(item, idx) in bill.fees" :key="'bill_item_'+idx">
                            <th scope="row">{{ idx + 1 }}</th>
                            <td>
                              <div>
                                <h5 class="text-truncate font-size-14 mb-1">{{ item.fee_code }} </h5>
                                <p class="text-muted mb-0"></p>
                              </div>
                            </td>
                            <td>
                              <div>
                                <h5 class="text-truncate font-size-14 mb-1">{{ item.fee_name }} </h5>
                                <p class="text-muted mb-0"></p>
                              </div>
                            </td>
                            <td>${{ f(item.fee).toLocaleString() }}</td>
                            <td>${{ f(item.fee_tax).toLocaleString() }}</td>
                            <td class="text-end">${{ f(item.fee + item.fee_tax).toLocaleString() }}</td>
                          </tr>
                          <!-- end tr -->

                          <tr>
                            <th scope="row" colspan="5" class="text-end">
                              Sub Total
                            </th>
                            <td class="text-end">${{ f(bill.sub_amount +bill.sub_amount_gst ).toLocaleString() }}</td>
                          </tr>
                          <!-- end tr -->
                          <tr>
                            <th scope="row" colspan="5" class="border-0 text-end">
                              Credit :
                            </th>
                            <td class="border-0 text-end">- ${{ f(bill.credit).toLocaleString() }}</td>
                          </tr>
                          <!-- end tr -->
                          <tr>
                            <th scope="row" colspan="5" class="border-0 text-end">
                              Total
                            </th>
                            <td class="border-0 text-end">
                              <h4 class="m-0 fw-semibold">${{  f(bill.sub_amount +bill.sub_amount_gst +bill.credit).toLocaleString() }}</h4>
                            </td>
                          </tr>
                          <!-- end tr -->
                        </tbody>
                        <!-- end tbody -->
                      </table>
                      <!-- end table -->
                    </div>
                    <!-- end table responsive -->
                  
                  </div>
                </div>
              </div>
              <!-- end card for-->
            </b-tab>
           <!-- end Tab Outstanding Bill-->

          

          </b-tabs>
        </div>

      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
